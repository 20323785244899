import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { 
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import { ConfirmationHero } from "../components/Hero";


const ConfirmationPage: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query {
      confirmationpage: mdx(
        frontmatter: {
          title: {
            eq: "Confirmation"
          }
        }
      ) {
        frontmatter {
          seo_title
          seo_description
          heading
          description
          hero_image {
            mobile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: {
                    fit: CONTAIN
                  }
                )
              }
            }
            desktop {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: {
                    fit: CONTAIN
                  }
                )
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title={ data.confirmationpage.frontmatter.seo_title }
        description={ data.confirmationpage.frontmatter.seo_description }
      />

      <SectionWrapper
        background="background.azureishwhite"
        padding={{
          base: '38px 0 32px',
          lg: '60px 0 48px'
        }}
      >
        <SectionContainer>
          <ConfirmationHero
            node={ data.confirmationpage.frontmatter }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default ConfirmationPage;
